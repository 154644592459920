import * as S from "./styles";

export type GridSectionProps = {
  title?: string;
  children: React.ReactNode;
  color?: "light" | "dark";
};

const GridSection = ({ children, title, color }: GridSectionProps) => (
  <S.Wrapper className="grid-section" color={color}>
    {!!title && <S.Title>{title}</S.Title>}
    <S.Grid className="grid-section_grid">{children}</S.Grid>
  </S.Wrapper>
);

export default GridSection;
