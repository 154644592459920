import Image from "components/Image";
import { ListingItem } from "domain/listingItem/types";
import { NftDraft } from "domain/nftDraft/types";
import Link from "next/link";
import QRCode from "qrcode.react";
import { useState } from "react";
import formatPrice from "utils/format-price";
import s3ToCloudFront from "utils/s3ToCloudFront";

import * as S from "./styles";
import NftCardVideo from "./video";

export type NftCardColor = "white" | "black";

export type NftCardProps = NftDraft & {
  price: number;
  listed?: boolean;
  availableAt?: Date;
  color?: NftCardColor;
  url?: string;
  qrCode?: string;
  listing?: ListingItem;
  listingTotal?: number;
  edition?: number;
};

const NftCard = ({
  objectUrl,
  title,
  shortDescription,
  slug,
  listed,
  price,
  objectType,
  url,
  color = "white",
  qrCode,
  publisher,
  editionQuantity,
  listingTotal,
  listing,
  edition,
  visibleAt,
  minted,
}: NftCardProps) => {
  const [loadingAsset, setLoadingAsset] = useState(false);
  const nftUrl =
    url || `/nft/${slug}${editionQuantity === 1 && minted ? `/edition/1` : ""}`;
  const qrCodeValue = url || qrCode || nftUrl;
  const currentBid = listing?.highestBid?.price;

  const visibleAtDate = new Date(visibleAt);
  const today = new Date();
  const isVisibleDateInThePast = visibleAtDate.getTime() < today.getTime();

  const priceLabel = listed
    ? edition
      ? currentBid
        ? "Current Bid"
        : listing?.type === "auction"
        ? "Reserve Price"
        : "Price"
      : "Lowest Ask"
    : "Status";
  const priceValue = listed
    ? formatPrice(price, { currencyDisplay: "symbol" })
    : "Not for sale";
  const editionLabel = edition ? "Edition #" : "Editions for Sale";
  const editionValue =
    edition ||
    (listingTotal &&
      editionQuantity &&
      `${listingTotal} of ${editionQuantity}`);

  const cardContent = (
    <S.ObjectBox className="nft-card__image" loadingAsset={loadingAsset}>
      {(objectType === "image" || objectType === "gif") && objectUrl && (
        <Image
          src={s3ToCloudFront(objectUrl)}
          alt={`Image NFT ${title}`}
          layout="fill"
          objectFit="contain"
          className="nft-card__image-image"
        />
      )}
      {objectType === "video" && objectUrl && (
        <NftCardVideo
          url={objectUrl}
          title={title}
          onCanPlay={() => setLoadingAsset(false)}
        />
      )}
    </S.ObjectBox>
  );

  const cardText = (
    <S.Info className="nft-card__info">
      <S.Title className="nft-card__title">{title}</S.Title>
      <S.Description
        aria-label={`NFT short description: ${shortDescription}`}
        className="nft-card__description"
      >
        {shortDescription}
      </S.Description>
    </S.Info>
  );

  return (
    <S.Wrapper color={color} className="nft-card">
      {isVisibleDateInThePast && (
        <Link href={nftUrl} passHref>
          {cardContent}
        </Link>
      )}
      {!isVisibleDateInThePast && cardContent}
      <S.LabelWrapper
        aria-label={`Published by ${publisher}`}
        className="nft-card__label-wrapper"
      >
        <S.Label aria-hidden="true" className="nft-card__label">
          {publisher}
        </S.Label>
      </S.LabelWrapper>
      <S.Content className="nft-card__content">
        <S.TextBox className="nft-card__content">
          {isVisibleDateInThePast && (
            <Link href={nftUrl} passHref>
              {cardText}
            </Link>
          )}
          {!isVisibleDateInThePast && cardText}
        </S.TextBox>
      </S.Content>
      <S.LineContainer className="nft-card__line-container">
        <S.Line />
      </S.LineContainer>
      <S.Footer className="nft-card__footer">
        <S.FooterBox className="nft-card__price-box">
          <S.PriceWrapper className="nft-card__footer-wrapper">
            <S.FooterLabel className="nft-card__price-title">
              {priceLabel}
            </S.FooterLabel>
            <S.FooterValue className="nft-card__price-value">
              {priceValue}
            </S.FooterValue>
          </S.PriceWrapper>
          {editionValue && (
            <div>
              <S.FooterLabel className="nft-card__price-title">
                {editionLabel}
              </S.FooterLabel>
              <S.FooterValue className="nft-card__price-value">
                {editionValue}
              </S.FooterValue>
            </div>
          )}
        </S.FooterBox>
        {qrCode && (
          <S.QRCode className="nft-card__qrcode-container">
            <QRCode
              value={qrCodeValue}
              size={100}
              className="nft-card__qrcode"
            />
          </S.QRCode>
        )}
      </S.Footer>
    </S.Wrapper>
  );
};

export default NftCard;
