import styled, { css } from "styled-components";

import { NftCardProps } from ".";

export const Wrapper = styled.article<Pick<NftCardProps, "color">>`
  ${({ theme, color }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${color === "white"
      ? theme.colors.white
      : theme.colors.mainBg};
    transition: transform ${theme.transition.default};
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: ${theme.border.radius};

    :hover {
      transform: translateY(-4px);
    }

    ${Title},
    ${FooterValue} {
      color: ${color === "white" ? theme.colors.mainBg : theme.colors.white};
    }

    ${FooterLabel} {
      color: ${color === "white"
        ? theme.colors.darkGray
        : theme.colors.lightGray};
    }
  `}
`;
interface ObjectBoxProps {
  loadingAsset: boolean;
}

export const ObjectBox = styled.a<ObjectBoxProps>`
  height: 36rem;
  width: 100%;
  position: relative;

  img,
  video {
    padding: ${({ theme }) => theme.spacings.xsmall} !important;
  }
  padding: ${({ theme }) => theme.spacings.xsmall};

  ${({ loadingAsset }) =>
    loadingAsset &&
    css`
      background: #f6f7f8;
      background-image: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
      );
      background-clip: content-box;
      animation: placeholderShimmer 1s linear infinite forwards;
    `}

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  top: 0;
  left: 0;
  position: absolute;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Label = styled.div`
  ${({ theme }) => css`
    font-family: "IBM Plex Sans";
    background-color: ${theme.colors.red};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
    border-radius: 0 ${theme.border.radius} ${theme.border.radius} 0;
    text-transform: uppercase;
    font-size: ${theme.font.sizes.xsmall};
    line-height: ${theme.font.sizes.medium};
    letter-spacing: 0.05rem;
    color: ${theme.colors.white};
    font-weight: 700;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;
    padding: ${theme.spacings.xsmall};
  `}
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

export const Info = styled.a`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-decoration: none;
    height: 162px;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xlarge};
    line-height: 2.4rem;
    font-weight: ${theme.font.bold};
    max-width: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    line-height: 2.4rem;
    margin-top: ${theme.spacings.xxsmall};
    color: ${theme.colors.black};
    max-width: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacings.xsmall};
`;

export const FooterBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xsmall};
  `}
`;

export const PriceWrapper = styled.div`
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FooterLabel = styled.span`
  ${({ theme }) => css`
    display: block;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: ${theme.font.sizes.xsmall};
    line-height: ${theme.font.sizes.medium};
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;

export const FooterValue = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    font-size: calc(${theme.font.sizes.xsmall}*2);
    line-height: ${theme.font.sizes.medium};
    margin-top: 0.4rem;
  `}
`;

export const QRCode = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border: 4px solid ${theme.colors.red};
    border-radius: ${theme.border.radius};
  `}
`;

export const LineContainer = styled.div`
  position: relative;
  height: 2px;
`;

export const Line = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to left,
    transparent 50%,
    rgba(189, 189, 189, 0.5) 50%
  );
  background-size: 30px 100%;
`;
