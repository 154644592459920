import styled, { css } from "styled-components";
import media, { defaultBreakpoints } from "styled-media-query";

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 48px;
    text-transform: uppercase;
    text-align: center;
  `}
`;

export const Wrapper = styled.section`
  ${({ theme, color = "light" }) => css`
    padding: ${theme.spacings.small} ${theme.spacings.xsmall};
    ${color === "light" && `background-color: #fafafa;`}
    ${color === "dark" &&
    css`
      background: linear-gradient(-45deg, #1c1e1e, #545e5e, #595f5f, #464848);
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
    `}

    ${Title} {
      margin-bottom: ${theme.spacings.medium};
    }

    ${media.greaterThan("medium")`
      background-color: #ffffff;
      padding: ${theme.spacings.small} 4rem;

      ${Title}{
        margin-bottom: ${theme.spacings.xlarge};
      }
    `}
  `}
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${theme.spacings.small};

    @media (min-width: 580px) and (max-width: ${defaultBreakpoints.medium}) {
      grid-template-columns: repeat(2, 1fr);
    }

    ${media.between("medium", "large")`
      grid-template-columns: repeat(3, 1fr);
    `}

    ${media.greaterThan("large")`
      grid-template-columns: repeat(4, 1fr);
    `}

    ${media.greaterThan("huge")`
      grid-template-columns: repeat(5, 1fr);
    `}
  `}
`;
