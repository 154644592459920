import { LazyLoadComponent } from "react-lazy-load-image-component";
import s3ToCloudFront from "utils/s3ToCloudFront";

import * as S from "./styles";

interface NftCardVideoProps {
  url: string;
  title: string;
  onCanPlay: () => void;
}

export default function NftCardVideo({
  url,
  title,
  onCanPlay,
}: NftCardVideoProps) {
  return (
    <LazyLoadComponent>
      <S.Video
        src={s3ToCloudFront(url)}
        title={`Video NFT ${title}`}
        loop
        playsInline
        muted
        className="nft-card__video"
        onCanPlay={onCanPlay}
        autoPlay
      />
    </LazyLoadComponent>
  );
}
